
.menu-bars {
    margin-right: 2rem;
    font-size: 2rem;
    right:0;
    justify-content: right;
  
    background: none;
  }
  
  .nav-menu {
    width: 300px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    z-index: 100;
  }
  
  .nav-menu.active {
    float:right;
    right: 0;
    transition: 350ms;
  }
  
  
  .navbar-toggle {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
  }
  
  span {
    margin-right: 10px;
  }